import React, { memo, Fragment } from "react";
import { useCurrentPlan } from "@/context/packagesubscription";
import { Camera, LineChart, CalendarCheck2 } from "lucide-react";
import Link from "next/link";

type Option = {
    link: string;
    name: string;
    icon: any;
};

const SubMenu = ({
    data,
    open,
    isPlanPresent,
    sessionExist,
    handleClose,
}: {
    data: Option[];
    isPlanPresent: boolean;
    open: boolean;
    handleClose: () => void;
    sessionExist: boolean;
}) => {
    const { disabledLiveClasses } = useCurrentPlan();

    console.log(data, "kk");

    return (
        <div className="top-8">
            {open && (
                <div
                    className={`fixed left-20 z-50 ${
                        data?.[0]?.link.includes("dashboard")
                            ? `${sessionExist ? "top-28" : "top-20"}`
                            : data?.[0]?.link.includes("learn") ||
                              data?.[0]?.link.includes("practice")
                            ? `${
                                  sessionExist
                                      ? isPlanPresent
                                          ? "top-84"
                                          : "top-56"
                                      : "top-72"
                              }`
                            : data?.[0]?.link.includes("compete")
                            ? `${
                                  sessionExist
                                      ? isPlanPresent
                                          ? "top-96"
                                          : "top-72"
                                      : "top-96"
                              }`
                            : `${
                                  sessionExist
                                      ? isPlanPresent
                                          ? "top-44"
                                          : "top-20"
                                      : "top-36"
                              }`
                    }  flex h-max w-52 flex-col overflow-hidden rounded-r-lg border-l-[3px] border-gray-500 bg-coolGray-200  pb-2 pt-4 ${
                        open ? "animate-slide-in" : "w-0"
                    } `}
                >
                    <div
                        className="relative w-52"
                        onClick={(e) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                        }}
                    >
                        {open &&
                            data.map((item, index) => {
                                if (
                                    item.name == "Live Classes" &&
                                    disabledLiveClasses
                                ) {
                                    return <Fragment key={index} />;
                                }

                                return (
                                    <Link
                                        key={index}
                                        href={item.link}
                                        className={` mb-2 flex w-full cursor-pointer items-center justify-start px-5 py-2 text-left text-sm font-medium text-black hover:bg-coolGray-400 hover:bg-opacity-60`}
                                        onClick={() => {
                                            handleClose();
                                        }}
                                    >
                                        {item.icon !== "" && (
                                            <img
                                                src={item.icon}
                                                width={20}
                                                height={20}
                                                alt=""
                                                className="mr-4 w-5"
                                            />
                                        )}
                                        {item.name === "Activity" && (
                                            <LineChart className="mr-3.5 w-5" />
                                        )}
                                        {item.name === "Snapshot" && (
                                            <Camera className="mr-3.5 w-5" />
                                        )}
                                        {item.name === "Planner" && (
                                            <CalendarCheck2 className="mr-3.5 w-5" />
                                        )}
                                        <span>{item.name}</span>
                                    </Link>
                                );
                            })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(SubMenu, (prevProps, nextProps) => {
    return (
        prevProps.open === nextProps.open &&
        prevProps.data === nextProps.data &&
        prevProps.handleClose === nextProps.handleClose
    );
});
