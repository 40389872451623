import { useEffect, useState } from "react";

const useFlashSaleTimer = () => {
    const [hours, setHours] = useState("00");
    const [minutes, setMinutes] = useState("00");
    const [seconds, setSeconds] = useState("00");

    useEffect(() => {
        const calculateTimeRemaining = () => {
            // Calculate the time remaining until 11:59 PM
            const now = new Date().getTime();
            const midnight = new Date(now);
            midnight.setHours(24, 0, 0, 0);
            const timeRemaining = new Date(midnight.getTime() - now);

            // Extract hours, minutes, and seconds
            const remainingHours = timeRemaining
                .getUTCHours()
                .toString()
                .padStart(2, "0");
            const remainingMinutes = timeRemaining
                .getUTCMinutes()
                .toString()
                .padStart(2, "0");
            const remainingSeconds = timeRemaining
                .getUTCSeconds()
                .toString()
                .padStart(2, "0");

            // Update state variables
            setHours(remainingHours);
            setMinutes(remainingMinutes);
            setSeconds(remainingSeconds);
        };

        // Calculate the initial time remaining
        calculateTimeRemaining();

        // Set an interval to update the timer every second
        const interval = setInterval(() => {
            if (parseInt(seconds) > 0) {
                setSeconds((parseInt(seconds) - 1).toString().padStart(2, "0"));
            } else {
                if (parseInt(minutes) > 0) {
                    setMinutes(
                        (parseInt(minutes) - 1).toString().padStart(2, "0")
                    );
                    setSeconds("59");
                } else {
                    if (parseInt(hours) > 0) {
                        setHours(
                            (parseInt(hours) - 1).toString().padStart(2, "0")
                        );
                        setMinutes("59");
                        setSeconds("59");
                    } else {
                        // The timer has reached 00:00:00, so restart it
                        calculateTimeRemaining();
                    }
                }
            }
        }, 1000);

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, [hours, minutes, seconds]);

    return { hours, minutes, seconds };
};

export default useFlashSaleTimer;
