/* eslint-disable max-lines */
import React, { Fragment, useState, useContext, useCallback } from "react";
import { SIDE_NAV_OPTIONS } from "@/constants";
import ModalsContext from "@/context/modals";
import { useCurrentPlan } from "@/context/packagesubscription";
import useSessionExist from "@/context/session/useSessionExist";
import { useRouter } from "next/router";
import SubMenu from "./SubMenu";

type Props = {
    visible: boolean;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    sideBarWidth: string;
};

function isEmptyObject(obj: any) {
    return Object?.getOwnPropertyNames(obj)?.length === 0;
}

const Sidebar = ({ isOpen, setIsOpen, visible }: Props) => {
    const router = useRouter();

    const sessionExist = useSessionExist();

    const packageSubscription = useCurrentPlan();

    const currentUrl = router.asPath;

    const [{ open, data, currentLink }, setOpen] = useState<{
        open: boolean;
        data: { name: string; link: string; icon: any }[];
        currentLink?: string;
    }>({ open: false, data: [], currentLink: "" });

    const { setLoginOpen } = useContext(ModalsContext);

    const handleClick = (link: string) => {
        const redirect = () => router.push(link);

        if (link === "/dashboard/snapshot") {
            if (sessionExist) {
                redirect();
            } else {
                setLoginOpen(true);
            }

            return;
        } else {
            redirect();
        }
    };

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <div
            className={`relative h-screen`}
            // onMouseEnter={() => {
            //     setIsOpen(true);
            // }}
            // onMouseLeave={() => {
            //     setIsOpen(false);
            // }}
        >
            <div
                id="sidebar"
                className={`hide-sidenav-scrollbar z-50 ${
                    visible ? "fixed shadow-md" : "hidden"
                } flex h-full flex-col overflow-y-scroll border-r-2 border-gray-300 bg-coolGray-200  pt-5 mix-blend-normal shadow-xl lg:fixed lg:flex lg:overflow-hidden`}
            >
                <div
                    className="relative"
                    onMouseLeave={() => {
                        setIsOpen(false);
                        setOpen({
                            open: false,
                            data: [],
                        });
                    }}
                >
                    {SIDE_NAV_OPTIONS.map(
                        ({ name, subMenu, icon, imageWidth, link }, index) => {
                            const borderClass =
                                currentUrl.includes("speed-math/practice") &&
                                name === "Speed"
                                    ? "border border-gray-400 border-opacity-40 bg-coolGray-400 bg-opacity-60 shadow-md"
                                    : currentUrl.includes(
                                          name.charAt(0).toLowerCase() +
                                              name.slice(1)
                                      ) &&
                                      !currentUrl.includes(
                                          "speed-math/practice"
                                      )
                                    ? "border border-gray-400 border-opacity-40 bg-coolGray-400 bg-opacity-60 shadow-md"
                                    : "";

                            const onMouseEnter = () => {
                                if (SIDE_NAV_OPTIONS[index].subMenu?.length) {
                                    setIsOpen(true);
                                    setOpen({
                                        open: true,
                                        data:
                                            SIDE_NAV_OPTIONS[index].subMenu ||
                                            [],
                                        currentLink:
                                            SIDE_NAV_OPTIONS[index].link,
                                    });
                                } else {
                                    setIsOpen(false);
                                    setOpen({
                                        open: false,
                                        data: [],
                                    });
                                }
                            };

                            if (link === "/plans") {
                                if (!sessionExist) {
                                    return <Fragment key={index} />;
                                }

                                if (!isEmptyObject(packageSubscription)) {
                                    return <Fragment key={index} />;
                                }
                            }

                            return (
                                <div
                                    className={`relative flex w-20 flex-col ${borderClass} ${
                                        index === 0 && link === "/free"
                                            ? sessionExist
                                                ? "hidden"
                                                : "block"
                                            : ""
                                    } ${
                                        index !== 0 && link === "/free"
                                            ? sessionExist
                                                ? packageSubscription?.is_a_top_pack
                                                    ? "hidden"
                                                    : "block"
                                                : "hidden"
                                            : ""
                                    } `}
                                    key={index}
                                >
                                    <div>
                                        {subMenu?.length ? (
                                            <Fragment>
                                                <div
                                                    className={`flex ${
                                                        open &&
                                                        data[0]?.link
                                                            .toLowerCase()
                                                            .includes(
                                                                name.toLowerCase()
                                                            )
                                                            ? "bg-coolGray-400"
                                                            : ""
                                                    } relative !z-50 cursor-pointer flex-col items-center justify-center py-4 text-xs  font-medium text-blue-3 hover:bg-coolGray-400 hover:bg-opacity-60 hover:text-blue-3`}
                                                    onClick={() => {
                                                        setOpen({
                                                            open: true,
                                                            data: subMenu,
                                                        });
                                                    }}
                                                    // onMouseLeave={onMouseLeave}
                                                    onMouseEnter={onMouseEnter}
                                                >
                                                    <img
                                                        src={icon}
                                                        alt=""
                                                        className={`mb-1`}
                                                        width={imageWidth}
                                                        height={20}
                                                        onClick={() => {
                                                            setOpen({
                                                                open: true,
                                                                data: subMenu,
                                                            });
                                                        }}
                                                    />
                                                    <p className="font-medium">
                                                        {name}
                                                    </p>
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <div>
                                                <div
                                                    onClick={() =>
                                                        handleClick(link)
                                                    }
                                                    className={`!z-50 flex cursor-pointer flex-col items-center rounded-md py-4 text-xs text-blue-3 hover:bg-coolGray-400 hover:bg-opacity-60 ${
                                                        currentLink === link &&
                                                        "bg-red-400 bg-opacity-60"
                                                    } `}
                                                    onMouseEnter={onMouseEnter}
                                                >
                                                    <img
                                                        src={icon}
                                                        alt=""
                                                        className={`mb-1`}
                                                        width={imageWidth}
                                                        height={20}
                                                    />
                                                    <p className="text-center font-medium">
                                                        {name}
                                                    </p>
                                                </div>
                                                {isOpen && (
                                                    <div className="">
                                                        <SubMenu
                                                            isPlanPresent={
                                                                isEmptyObject(
                                                                    packageSubscription
                                                                )
                                                                    ? true
                                                                    : false
                                                            }
                                                            sessionExist={
                                                                sessionExist
                                                            }
                                                            open={open}
                                                            data={data}
                                                            handleClose={
                                                                handleClose
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>
                {isOpen && (
                    <div className="fixed top-0 ml-20 h-screen w-screen bg-[#3C3C3C] bg-opacity-60" />
                )}
            </div>
        </div>
    );
};

export default Sidebar;