import React from "react";
import {
    HoursLeft,
    MinutesLeft,
    SecondsLeft,
} from "@/pages/plans/components/FlashSaleTimer";
import useFlashSaleTimer from "@/pages/plans/hooks/useFlashSaleTimer";

import { useRouter } from "next/router";
import { getImageUrl } from "@/utils";

const PlansRibbon = ({ CloseRibbon }: { CloseRibbon: () => void }) => {
    const { hours, minutes, seconds } = useFlashSaleTimer();

    const router = useRouter();

    const handleRoute = () => {
        router.push("/plans");
    };

    return (
        <div className="relative mt-0.5 flex w-full flex-col items-center justify-center bg-dark-gray-3 py-2 text-yellow-700 md:flex-row">
            <p className="text-lg font-bold sm:mr-18">
                Hurry! Study Pack at ₹ 2999
            </p>
            <div className="mt-1 flex items-center md:mt-0">
                <div className="flex gap-x-3">
                    <HoursLeft hours={hours} />
                    <MinutesLeft minutes={minutes} />
                    <SecondsLeft seconds={seconds} />
                </div>
                <button
                    onClick={handleRoute}
                    className="ml-4 rounded-md bg-yellow-700 px-6 py-1.5 font-semibold text-dark-gray-3 md:ml-12"
                >
                    View Plans
                </button>
                <img
                    src={getImageUrl("/images/ribbon-close.svg")}
                    alt=""
                    className="absolute right-4 top-4"
                    onClick={CloseRibbon}
                />
            </div>
        </div>
    );
};

export default PlansRibbon;
