/* eslint-disable max-lines */
import React, { Fragment } from "react";
import { useCurrentUser } from "@/context/currentUser";
import useModalContext from "@/context/modals/useModalContext";
import { useCurrentPlan } from "@/context/packagesubscription";
import { getImageUrl } from "@/utils";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/solid";
// @ts-ignore
import Link from "next/link";
import { useRouter } from "next/router";
import routes from "routes";

function isEmptyObject(obj: any) {
    return Object?.getOwnPropertyNames(obj)?.length === 0;
}

const UpgradePlan = () => {
    const router = useRouter();

    return (
        <div
            className="relative hidden cursor-pointer md:block"
            onClick={() => {
                router.push("/plans");
            }}
        >
            <div className="flex items-center">
                <img
                    src={getImageUrl("/images/upgrade-icon.svg")}
                    alt=""
                    width={26}
                    className="relative -right-[18px] -top-[0.4px]"
                    height={26}
                />
                <img
                    src={getImageUrl("/images/upgrade-bg.png")}
                    alt=""
                    width={157}
                    className="h-[38px]"
                    height={38}
                />
            </div>
            <p className="absolute left-1/2 top-1/2 -translate-x-1/3 -translate-y-1/2 transform text-base font-bold">
                Upgrade{" "}
            </p>
        </div>
    );
};

type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    sideBarWidth: string;
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    theme?: string;
};

const ProgressBar = ({ value }: { value: number }) => {
    return (
        <div className="flex h-[10px] w-full rounded-[20px] border border-zinc-300 md:h-[15px]">
            <div
                className="m-[1px] h-[6px] w-19 rounded-[20px] bg-green-500 md:h-[11px] md:w-[105px]"
                style={{
                    width: `${value}%`,
                }}
            ></div>
        </div>
    );
};

const Navbar = ({ isOpen, setIsOpen, visible, setVisible }: Props) => {
    const router = useRouter();

    const toggleNavbar = () => {
        setVisible(!visible);
        setIsOpen(isOpen);
    };

    const packageSubscription = useCurrentPlan();

    const { level, currentXp, avatar, isAdmin, handleLogout, isLoggedIn } =
        useCurrentUser();

    const { handleLoginOpen } = useModalContext();

    return (
        <div className="sticky top-0 z-[1000] flex w-full items-center justify-between bg-blue-3 px-6 py-2">
            <div className="flex flex-row items-center ">
                <Bars3Icon
                    className="block h-8 w-8 cursor-pointer text-white lg:hidden"
                    onClick={toggleNavbar}
                />
                <div className="block md:hidden">
                    <img
                        src={getImageUrl("/images/logo.png")}
                        alt=""
                        width={100}
                        height={80}
                        className="cursor-pointer"
                        onClick={() => {
                            router.push(routes.home().href);
                        }}
                    />
                </div>
                <div className="hidden md:block">
                    <img
                        src={getImageUrl("/images/logo.png")}
                        alt=""
                        width={187}
                        height={40}
                        className="h-[42px] cursor-pointer md:ml-16"
                        onClick={() => {
                            router.push(routes.home().href);
                        }}
                    />
                </div>
            </div>
            <div className="flex items-center justify-end">
                {/* {theme && (
                    <label className="inline-flex cursor-pointer items-center">
                        <input
                            onChange={() => toggleTheme()}
                            type="checkbox"
                            value=""
                            className="peer sr-only"
                        />
                        <div className="peer-focus:ring-blue-300 peer-checked:bg-blue-600 peer relative h-5 w-9 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700"></div>
                        <span className="ms-3 text-sm font-medium text-gray-400">
                            {theme.toLocaleUpperCase()} MODE
                        </span>
                    </label>
                )} */}
                {isLoggedIn ? (
                    <Fragment>
                        <div className="flex items-center justify-end">
                            {isAdmin ? (
                                <Fragment>
                                    <Link href="/admin">
                                        <p className="mx-4 mr-20 flex cursor-pointer items-center justify-center  text-xl font-semibold text-white">
                                            Admin
                                        </p>
                                    </Link>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    {isEmptyObject(packageSubscription) ? (
                                        <UpgradePlan />
                                    ) : (
                                        <Fragment>
                                            {packageSubscription?.is_a_top_pack ||
                                            !packageSubscription?.expired ? (
                                                <Fragment />
                                            ) : (
                                                <UpgradePlan />
                                            )}
                                            <div className="flex w-24 flex-col md:mx-4 md:w-[154px]">
                                                <div
                                                    className="mb-1 flex items-center justify-between
                    "
                                                >
                                                    <p className="pl-2 text-center text-10 font-bold text-sky-50 md:text-[13px]">
                                                        Level {level}
                                                    </p>
                                                    <p className="flex pr-2 text-right text-10 font-bold text-sky-50 md:text-xs">
                                                        <span>{currentXp}</span>
                                                        <span className="hidden md:flex">
                                                            &nbsp;/ 200
                                                        </span>{" "}
                                                        xp
                                                    </p>
                                                </div>
                                                <ProgressBar
                                                    value={currentXp / 2}
                                                />
                                            </div>
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                            <Popover className="relative z-[10000]">
                                {() => (
                                    <>
                                        <Popover.Button className="outline-none focus:outline-none">
                                            <div className="relative h-8 w-8 md:min-w-[60px]">
                                                <img
                                                    src={
                                                        avatar ||
                                                        getImageUrl(
                                                            "/images/avatar.svg"
                                                        )
                                                    }
                                                    width={60}
                                                    height={60}
                                                    alt=""
                                                    className="absolute -top-1 right-0 h-12 w-12 md:min-w-[60px]"
                                                />
                                            </div>
                                        </Popover.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="opacity-0 translate-y-1"
                                            enterTo="opacity-100 translate-y-0"
                                            leave="transition ease-in duration-150"
                                            leaveFrom="opacity-100 translate-y-0"
                                            leaveTo="opacity-0 translate-y-1"
                                        >
                                            <Popover.Panel className="absolute -left-[19px] z-10 mt-3 max-w-sm -translate-x-1/2 transform py-2 sm:px-0 md:left-[10px] 2md:left-[12px] 2md:px-0 lg:left-0  lg:max-w-3xl ">
                                                <div className="flex flex-col bg-gray-2 py-2">
                                                    {!isAdmin && (
                                                        <button
                                                            onClick={() => {
                                                                router.push(
                                                                    "/profile"
                                                                );
                                                            }}
                                                            className="mx-4 mb-2 w-28 rounded bg-blue-3 px-4 py-2 text-center  text-sm font-semibold text-white"
                                                        >
                                                            Edit Profile
                                                        </button>
                                                    )}

                                                    <button
                                                        onClick={() => {
                                                            handleLogout();
                                                        }}
                                                        className="mx-4 mb-2 w-28 rounded bg-blue-3 px-4 py-2 text-center  text-sm font-semibold text-white"
                                                    >
                                                        Logout
                                                    </button>
                                                </div>
                                            </Popover.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Popover>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="flex items-center justify-end">
                            <button
                                onClick={handleLoginOpen}
                                className="relative  mx-3 flex h-max items-center justify-center rounded-3xl bg-[#DFE9FD] px-4 py-1 text-xs font-semibold text-primary-1"
                            >
                                <Fragment>
                                    <img
                                        src={getImageUrl("/images/person.svg")}
                                        alt=""
                                        width={20}
                                        height={20}
                                        className="mr-1"
                                    />
                                    <p className="px-2 text-lg font-bold">
                                        Login
                                    </p>
                                </Fragment>
                            </button>
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default Navbar;
