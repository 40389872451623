import React from "react";
import useFlashSaleTimer from "../../hooks/useFlashSaleTimer";

export const HoursLeft = ({ hours }: { hours: string }) => {
    return (
        <div className="font-digital flex">
            <div className="mr-1.5 rounded !border-[0.204px] border-gray-400 bg-opacity-80 bg-gradient-to-b from-[#919090] via-[#605f5f] to-[#4e4d4d] p-1">
                {hours.toString().slice(0, 1)}
            </div>
            <div className="rounded !border-[0.204px] border-gray-400 bg-opacity-80 bg-gradient-to-b from-[#919090] via-[#605f5f] to-[#4e4d4d] p-1">
                {hours.toString().slice(1)}
            </div>
        </div>
    );
};

export const MinutesLeft = ({ minutes }: { minutes: string }) => {
    return (
        <div className="font-digital flex">
            <div className="mr-1.5 rounded !border-[0.204px] border-gray-400 bg-opacity-80 bg-gradient-to-b from-[#919090] via-[#605f5f] to-[#4e4d4d] p-1">
                {minutes.toString().slice(0, 1)}
            </div>
            <div className="rounded !border-[0.204px] border-gray-400 bg-opacity-80 bg-gradient-to-b from-[#919090] via-[#605f5f] to-[#4e4d4d] p-1">
                {minutes.toString().slice(1)}
            </div>
        </div>
    );
};

export const SecondsLeft = ({ seconds }: { seconds: string }) => {
    return (
        <div className="font-digital flex">
            <div className="mr-1.5 rounded !border-[0.204px] border-gray-400 bg-opacity-80 bg-gradient-to-b from-[#919090] via-[#605f5f] to-[#4e4d4d] p-1">
                {seconds.toString().slice(0, 1)}
            </div>
            <div className="rounded !border-[0.204px] border-gray-400 bg-opacity-80 bg-gradient-to-b from-[#919090] via-[#605f5f] to-[#4e4d4d] p-1">
                {seconds.toString().slice(1)}
            </div>
        </div>
    );
};

const FlashSaleTimer = () => {
    const { hours, minutes, seconds } = useFlashSaleTimer();

    return (
        <div className="mt-1 flex bg-dark-gray-3 px-2 pb-1 pl-4 pt-2 text-2xl font-semibold text-yellow-700 sm:px-4 sm:pt-4 sm:text-5xl">
            <div className="mr-2 flex flex-col items-center sm:mr-4">
                <HoursLeft hours={hours} />
                <p className="mt-1 text-base font-normal text-white sm:text-xl">
                    HOURS
                </p>
            </div>
            <div className="mr-2 flex flex-col items-center sm:mr-4">
                <MinutesLeft minutes={minutes} />
                <p className="mt-1 text-base font-normal text-white sm:text-xl">
                    MINUTES
                </p>
            </div>
            <div className="flex flex-col items-center">
                <SecondsLeft seconds={seconds} />
                <p className="mt-1 text-base font-normal text-white sm:text-xl">
                    SECONDS
                </p>
            </div>
        </div>
    );
};

export default FlashSaleTimer;
